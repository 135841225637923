@import 'styles/variables';

.assets-list {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  position: relative;

  &__items {
    // 2px top for the shadow when asset is hovered
    padding: 16px 10px 2px 10px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
  }

  &__header {
    padding: 10px 20px 4px 20px;
    position: relative;
    z-index: 2;
  }

  .scroll-container {
    flex-grow: 1;
  }

  &__uploader {
    position: absolute;
    z-index: 1;
    top: 10px;
    left: 10px;
    right: 0;
    bottom: 0;
    padding: 0;

    &--file {
      top: 16px;

      .file-input {
        &__wrap {
          .input-container {
            width: 184px;
            height: 112px;
            border-radius: 8px;
            border: 1px dashed $media-text-lightgray;
          }

          display: block;
          text-align: left;
          min-height: 651px;
          max-height: 100%;
          border-radius: 0;

          svg {
            width: 24px;
            height: 24px;

            display: block;
            margin: 0 auto 8px;
          }

          p {
            text-align: center;
            max-width: 170px;
          }
        }
      }
    }

    &--full {
      top: 0;

      .file-input {
        &__wrap {
          height: calc(100% - 20px);
          margin: 0 20px 0px;
          font-size: 18px;

          border-radius: 8px;
          border: 1px dashed $media-text-lightgray;

          svg {
            width: 44px;
            height: 44px;
            margin-bottom: 12px;
          }

          p {
            font-size: 12px;
            max-width: unset;
          }
        }
      }
    }

    .import-anchor {
      color: $blue;
      white-space: nowrap;
      word-break: keep-all;
    }

    .file-input {
      width: 100%;
      height: 100%;
    }

    &-placeholder {
      width: 204px;
      height: 112px;
      position: relative;
      z-index: -1;
      padding: 0 10px 16px;
    }
  }
}