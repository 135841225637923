.volumeMeter {
  position: relative;
  display: flex;
  align-items: center;
  margin-right: 24px;
  width: 250px;
  display: flex;
  align-items: center;
}

.sliderRoot {
  position: absolute;
  height: 6px;
  padding: 5px 0;
}

.rail {
  height: 6px;
  color: var(--card-item-background);
}

.track {
  height: 6px;
  color: transparent;
}

.thumb {
  z-index: 2;
  margin-top: -3px;
  color: var(--text-color-light);
}

.rail1 {
  height: 6px;
  color: transparent;
}

.track1 {
  z-index: 1;
  height: 6px;
  color: var(--activity-color);
}

.thumb1 {
  display: none;
}