@use '~styles/mixins.scss' as mixins;

.container {
  --main-color: var(--activity-color);
  position: relative;
  min-height: 98px;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding: 16px;
  background-color: var(--control-background);
  border-radius: 4px;
  font-size: 14px;
}

.header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.title {
  font-size: 16px;
}

.track {
  display: flex;
  align-items: center;
}

.divider {
  height: auto;
  margin: 0 12px;
  align-self: stretch;
  background-color: #474747;
}

.name {
  flex-grow: 1;
  @include mixins.ellipsisTextOverflow
}

.controls {
  width: auto;
  flex-shrink: 0;
  color: var(--main-color);
  font-size: 18px;
  &.play {
    padding: 3px;
    background-color: #696969;
    border-radius: 3px;
    margin-right: 8px;
    svg, path {
      stroke: white;
      fill: white;
    }
  }
  &.retake {
    margin-left: auto;
    svg path {
      stroke: var(--main-color);
      fill: var(--main-color);
    }
  }
  &.delete {
    svg path {
      fill: var(--main-color);
    }
  }
  &.no {
    border: 1px solid var(--text-color);
    color: var(--text-color);
    margin-right: 12px;
    margin-left: auto;
  }
  &.yes {
    color: var(--background-color);
    background-color: var(--text-color);
  }
  &.disabled {
    color: white;
  }

  & .icon {
    width: 18px;
    height: 18px;
  } 
  & .stopIcon {
    width: 10px;
    height: 10px;
    & svg {
      fill: transparent;
    }
  }
}


.progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  background-repeat: repeat-x;
  background-size: 6px 4px;
  background-image: linear-gradient(-70deg, var(--activity-color) 4px, var(--text-color) 4px, var(--text-color) 6px, var(--activity-color) 6px);
  animation: progress .3s linear infinite
}

.circularProgress {
  width: 12px;
  height: 12px;
  margin: 3px;
  color: var(--text-color);
}

@keyframes progress {
  to {
    background-position: 6px 0;
  }
}