@import "styles/variables";

// name is deprecated. now z-index is setting up by layerIndex
$text-overlay-z-index: 3;

.preview-player {
  $root: #{&};

  height: 100%;
  width: 100%;
  min-width: 320px;
  background-color: var(--border-color);
  position: relative;

  &--full {
    position: fixed !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
  }

  &__video {
    background-color: var(--panel-color);
    height: calc(100% - 50px);

    #{$root}--full & {
      height: 100%;
    }
  }

  &__text-overlay {
    position: absolute;
    left: 0;
    top: 0;

    width: 100%;
    height: 100%;

    &--hidden {
      visibility: hidden;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    // ---
    // This animation masks a bad-looking visual effect when text nodes jump to their new positions when fullscreen enables.
    // It happens because screen is re-measured and coords recalculated in `useEffect` –
    // so it's inevitable that *first* overlay is resized, and *then* coords are updated (after resized overlay is already rendered).
    // Making overlay invisible for a moment should kinda hide this effect from user.
    // Duration is chosen empirically; just hope it's enough.

    @mixin fullscreen-transition($direction, $duration) {
      $name: preview-text-overlay-fullscreen-transition-#{$direction};

      @keyframes #{$name} {

        0%,
        50% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }

      animation-name: $name;
      animation-duration: $duration;
      animation-timing-function: ease-in;
    }

    #{$root}--full & {
      @include fullscreen-transition("in", 0.5s);
    }

    @include fullscreen-transition("out", 0.3s);
  }

  &__toolbar {
    #{$root}--full & {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;

      background-color: rgba(var(--background-color), .8);
      transition: all .3s;
      opacity: 0;
      // TODO:
      //  without this, toolbar can't be hovered and thus can't be accessed in fullscreen
      //  but, what if some text is at the very bottom of video?
      //  Toolbar will appear, cover it, and that text can't be grabbed anyhow.
      z-index: 60;

      &:hover {
        opacity: 1;
      }
    }
  }
}

.preview-player {
  position: absolute;
  top: 0px;
}

// NOTE: this delay of the spinner visibility is used for hide spinner for small loadings (less than 0.3s)
.progress-spinner {
  animation: visibilityAnimation 0s 0.3s forwards;
  visibility: hidden;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
}

@keyframes visibilityAnimation {
  to {
    visibility: visible;
  }
}

.initializing-transition-plug {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  z-index: $text-overlay-z-index + 1;
  background: black;
}


.transformable-transition {
  z-index: $text-overlay-z-index + 1;
  position: absolute;
  width: 100%;
  height: 100%;
}

.transition-transformer {
  position: absolute;
  top: 0;
  left: 0;
}

.video-player {
  display: block;
  margin: auto;
}

.media-image-preview {
  background-color: var(--panel-color);
  height: 100%;
  width: 100%;

  img {
    max-height: 100%;
    max-width: 100%;
  }

  display: flex;
  justify-content: center;
  align-items: center;
}

.player {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}