.presetDropdown {
  width: 194px;
  margin: 0 8px 0 4px;
}

.presetConfigLine {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 12px;
}

.conflictMessage {
  color: var(--text-color-secondary);
}

.hotkeysSearch {
  input {
    height: 32px;
  }
}

.hotkeyChip {
  margin-right: 4px;
}

.presetChips {
  display: flex;
  margin-top: 20px;
}

.undoIcon {
  path {
    fill: var(--text-color);
  }
}

.undoButton {
  font-size: 14px;
  padding: 8px 12px;
}

.hotkeyDropdown {
  transform: translateX(-70px) !important;
  width: 100px !important;
}