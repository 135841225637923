@import "styles/variables.scss";

.media-tooltip .MuiTooltip-tooltip {
  max-width: 200px;
  background: var(--bg-29);
  border: none;
  outline: none;
}

.media-info__value{
  color: white !important;
}

.source-asset {
  width: 184px;
  cursor: pointer;
  margin: 0 10px 16px;

  // ---

  &__content {
    width: 100%;
    height: 112px;

    border-radius: 4px;
    overflow: hidden;

    &--drop-target {
      box-shadow: 0 0 0 2px #{$blue};
    }
  }

  &__name,
  .folder-name input {
    margin-top: 8px;
    text-align: center;
    font-size: 12px;
    color: var(--text-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .folder-name {
    width: fit-content;
    margin: auto;
  }

  // ---
  // border for selected / hovered state

  $self: #{&};

  &:hover {
    --shadow-color: #{var(--text-color)};

    #{$self}__content {
      box-shadow: 0 0 0 2px var(--shadow-color);
    }
  }

  &--selected {
    --shadow-color: #{$blue};

    #{$self}__content {
      box-shadow: 0 0 0 2px var(--shadow-color);
    }

    &:hover {
      --shadow-color: #{$blue};

      #{$self}__content {
        box-shadow: 0 0 0 4px var(--shadow-color);
      }
    }
  }

  &--dragging {
    opacity: 0.5;
    margin: 0;
  }

  // ---

}

.media-info__name {
  color: white;
}