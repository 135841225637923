.timeline-footer {
    height: 52px;
    background-color: var(--background-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    z-index: 2;

    &__btns {
        display: flex;
        &--disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }

    &__btn {
        padding-left: 10px;

        .MuiButton-root {
            height: 32px;
            font-size: 18px;
            text-transform: none;
            line-height: 1;
            box-shadow: none;
            transition: all .3s;

            .MuiTouchRipple-root {
                display: none;
            }
        }

        .MuiButton-containedPrimary {
            background-color: var(--activity-color);

            &:hover {
                background-color: var(--activity-color-hovered);
            }
        }

        .MuiButton-outlined {
            border: 1px solid var(--text-color);
            color: var(--text-color);

            &:hover {
                border-color: var(--text-color);
                color: var(--text-color);
            }
        }
    }

    &__duration {
        color: var(--text-color-light);

        span {
            color: var(--text-color);
        }
    }
}
