@import "./base";

.header-solveig {
  @extend .header;

  img {
    max-width: 33px;
  }
}

.header {
  &__link {
    img {
      max-width: 33px!important;
    }
  }
  &__nav {
    &__tabs {
      display: flex;
      margin-right: 18px;

      &__tab {
        display: flex;
        align-items: center;
        padding: 8px 14px 12px;
        position: relative;

        font-size: 14px;
        line-height: 16px;
        color: var(--text-color);
        cursor: pointer;

        > :first-child {
          margin-right: 5px;
        }

        &:hover {
          background: var(--card-item-background);
          border-radius: 4px 4px 0px 0px;
        }

        &--active,
        &--active:hover {
          background: var(--card-background);
          border-radius: 4px 4px 0px 0px;

          &:after {
            content: "";
            width: 100%;
            height: 3px;

            background: var(--activity-color);
            border-radius: 1.5px;

            position: absolute;
            bottom: 0;
            left: 0;
          }
        }

        svg {
          path {
            fill: var(--text-color);
          }
          rect {
            stroke: var(--text-color);
          }
        }
      }
    }
  }
}
