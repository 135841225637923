.base-view {
  padding: 20px;
  margin-bottom: 20px;
  color: var(--text-color);

  &_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
  }

  &_caption {
    font-size: 24px;
    color: var(--text-color);
  }

  &_close {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:hover {
      svg {
        fill: var(--text-color);
      }
    }

    svg {
      fill: var(--text-color-light);
      transition: all .3s;
    }
  }

  &_footer {
    font-size: 12px;
    color: var(--text-color);
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: var(--background-color);
    box-shadow: 0px -3px 8px 0px rgba(0, 0, 0, 0.25);
    padding: 12px 20px;
    z-index: 2;
  }
}