@import "styles/variables";

.preview-player-toolbar {
  $root: #{&};

  z-index: 60;
  height: 50px;
  background-color: $background-color;
  position: relative;

  // ---

  &__slider {
    height: 26px !important;
    top: -14px !important;

    > * {
      position: absolute;
      bottom: 9px;
    }

    .MuiSlider-thumb {
      bottom: 6px;
    }
  }

  &__timeline {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 3px;
    background-color: var(--player-track-color);

    &:before {
      position: absolute;
      content: "";
      left: 0;
      top: 0;
      width: 6px;
      height: 3px;
      background-color: var(--player-slider-color);
    }

    .MuiSlider-root {
      padding: 0;
      height: 3px;
      position: absolute;
      top: 0;
      left: 5px;
      width: calc(100% - 8px);
    }

    .MuiSlider-rail {
      height: 3px;
      background-color: var(--player-track-color);
    }

    .MuiSlider-track {
      height: 3px;
      background-color: var(--player-slider-color);
    }

    .MuiSlider-thumb {
      width: 9px;
      height: 9px;
      margin-top: -4px;
      background-color: var(--player-slider-color);

      &:hover {
        box-shadow: none;
      }
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 16px;
    line-height: 1;
  }

  // ---

  &__left {
    color: var(--text-color);
    font-size: 16px;
    width: 85px;
  }

  &__center {
    display: flex;
    align-items: center;

    svg {
      &:hover {
        path, rect { 
          fill: var(--bg-56);
        }
      }
      path, rect { 
        fill: var(--text-color);
      }
    }
  }

  &__right {
    display: flex;
    align-items: center;

    svg path {
      fill: var(--text-color);
    }

    #{$root}__item {
      &:last-child {
        margin-right: 0;
      }
    }
  }

  // ---

  &__item {
    margin: 0 7px;

    .raw-button {
      position: relative;

      width: 18px;
      height: 18px;

      display: flex;
      align-items: center;
      justify-content: center;
      
      &[disabled] svg path {
        &[stroke] {
          stroke: #8f8f8f;
        }
        &[fill] {
          fill: #8f8f8f;
        }
      }

      &.add-icon {
        height: 24px;
        width: 24px;
        fill: var(--text-color);
        background-color: var(--activity-color);

        &:hover {
          background-color: var(--activity-color-hovered);
        }
      }
    }

    .revert-icon {
      transform: scale(-1, 1);
    }
  }

  &__volume {
    position: relative;

    height: 115px;
    padding: 8px 12px;

    border: 1px solid #3c3c3c;
    border-radius: 4px;

    background-color: var(--border-color);

    &:before {
      content: "";

      position: absolute;
      top: 50%;
      left: 3px;

      width: 6px;
      height: 1px;

      background-color: #3d3d3d;
      transform: translateY(-50%);
    }

    &:after {
      content: "";

      position: absolute;
      width: 6px;
      height: 1px;

      top: 50%;
      right: 3px;

      background-color: #3d3d3d;
      transform: translateY(-50%);
    }

    // ---

    &-tooltip {
      &.MuiTooltip-tooltip {
        padding: 0;
        margin: 0;
      }
    }

    // ---

    .MuiSlider-root.MuiSlider-vertical {
      padding: 0;
    }

    .MuiSlider-vertical .MuiSlider-thumb {
      width: 9px;
      height: 9px;
      margin-left: -3px;
      margin-bottom: -4px;
      background-color: var(--text-color);

      &:hover {
        box-shadow: none;
      }
    }

    .MuiSlider-rail {
      background-color: #5f5f5f;
    }

    .MuiSlider-vertical .MuiSlider-track {
      background-color: #536ade;
    }
  }
}