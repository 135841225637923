@import "styles/variables.scss";

.layer-item {
  position: absolute;
  border: 1px solid var(--bg-68);
  background-size: cover;
  background-position: 50% 50%;
  border-radius: 4px;
  // TODO: it broke transition z order, transition is under the asset after transform
  // height: 58px;
  // top: 50%;
  // transform: translateY(-50%);
  top: 2px;
  bottom: 2px;
  opacity: 1;
  $root: #{&};

  &:hover {
    border-color: var(--text-color);

    #{$root}--image,
    #{$root}--text {
      &__expander {
        z-index: 1;
        display: flex;
      }
    }
  }

  &--selected {
    border-color: var(--activity-color);
    
    #{$root}--image,
    #{$root}--text {
      &__expander {
        z-index: 1;
        background-color: var(--activity-color);
        
        &--hide {
          background-color: transparent;
        }
      }
    }

    &:hover {
      border-color: var(--activity-color);
    }
  }

  &--dragging {
    // NOTE: display: none; is important to hide elem from the DOM
    display: none;
  }

  &--text {
    background: linear-gradient(to bottom, var(--absolute-color), var(--absolute-color) 43px, var(--activity-color-light) 17px, var(--activity-color-light));
    color: var(--text-color);
    font-size: 14px;
    font: Arial;

    &__text {
      margin-left: 6px;
      margin-top: 13px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    &__description {
      margin-left: 7px;
      margin-top: 14px;
      font: Arial;
      font-size: 10px;
      color: var(--absolute-color);
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &--image {
    background: var(--layer-item-background);

    &__text {
      height: 100%;
      display: flex;
      align-items: center;

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: var(--text-color);
      font-size: 10px;
      line-height: 11px;
    }
  }

  &--audio {
    overflow: hidden;
    background: var(--layer-item-background);
  }

  &--image,
  &--text {
    &__expander {
      z-index: 1;
      width: 9px;
      height: 100%;
      display: none;
      background-color: rgba(255, 255, 255, 0.64);
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      justify-content: center;
      cursor: ew-resize;
      
      position: absolute;
      left: 0px;
      
      &::after {
        content: "";
        width: 2px;
        height: 28px;
        transform: rotate(0deg);
        background: rgba(49, 49, 49, 1);
        border-radius: 0.5px;

        position: absolute;
        top: 25%;
        left: 3px;
      }

      &--right {
        left: auto;
        right: 0px;
        border-radius: 0px;
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
        &::after {
          left: auto;
          right: 3px;
        }
      }
      
      &--selected {
        &::after {
          background: var(--text-color);
        }
      }

      &--enable {
        display: flex;
      }

      &--hide {
        width: 4px;
        background-color: transparent;

        &::after {
          visibility: hidden;
        }
      }
    }
  }

  &--deleted {
    display: flex;
    overflow: hidden;
    align-items: center;
    height: 100%;

    &__elem {
      margin-left: 4px;
      color: var(--text-color);
      display: flex;
      align-items: center;

      &__icon {
        content: "!";
        display: block;
        font-size: 24px;
        background: red;
        border-radius: 50%;
        height: 30px;
        width: 30px;
        min-width: 30px;
        text-align: center;
        font-weight: bold;
        margin-right: 4px;
      }

      &__text {
        font-size: 12px;
        width: 70px;
      }
    }
  }

  &__transition {
    background: white;
    z-index: 1;
    top: 0px;
    bottom: 0px;

    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &#{$root}--selected {
      background: var(--activity-color);
    }
  }
}

.transition-placeholder {
  position: absolute;
  z-index: 1;
  top: -3px;
}

.layer-thumbnail {
  position: relative;
  height: 100%;
}
