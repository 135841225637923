.customization {
  padding: 20px;
  margin-bottom: 20px;

  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
  }

  &__caption {
    font-size: 24px;
    color: var(--text-color);
  }

  &__close {
    width: 24px;
    height: 24px;
    cursor: pointer;

    &:hover {
      svg {
        fill: var(--text-color);
      }
    }

    svg {
      fill: var(--text-color-light);
      transition: all .3s;
    }
  }

  &_formfield {
    max-width: 50%;
  }

  &_logo {
    display: flex;
  }

  &_logo-example {
    position: absolute;
    top: 62px;
    right: 0;

    svg {
      path {
        &:first-child {
          fill: var(--panel-color);
        }

        &:nth-last-child(1),
        &:nth-last-child(2),
        &:nth-last-child(3),
        &:nth-last-child(4) {
          fill: var(--text-color);
        }
      }

      rect {
        fill: var(--activity-color);
      }

      path:nth-child(2),
      path:nth-child(3),
      path:nth-child(4) {
        fill: var(--text-color);
      }

      path:nth-child(5) {
        stroke: var(--activity-color);
      }
    }
  }

  &_logo_buttons {
    margin: 10px 0;
    display: flex;
    align-items: center;
    font-size: 14px;
    > * {
      &:last-child {
        margin-left: 36px;
        display: flex;
        align-items: center;

        > *:last-child {
          margin-left: 8px;
          cursor: pointer;
          user-select: none;
        }
      }
    }
  }

  &_logo_upload {
    max-width: 123px;
  }

  &_logo_delete {
    margin-left: 10px;
  }

  &_logo_example {
    margin: 10px 0;
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
    }
    &_name {
      font-size: 12px;
    }
  }

  &_edit-css {
    display: flex;
    justify-content: space-between;
    &_text {
      max-width: 75%;
    }
    .btn {
      width: 110px;
    }
  }

  &_edit-btns {
    display: flex;
    align-items: center;
    > *:last-child {
      margin-left: 20px;
    }
  }

  &_edit-btn {
    width: fit-content;
    margin: 8px 0;
    font-size: 16px;
  }

  .btn {
    font-size: 14px;
  }

  &_no-logo {
    font-style: italic;
    display: flex;
    align-items: center;
    font-size: 12px;
    margin: 8px 0;
    color: var(--text-color-light);
    &::before {
      content: '';
      display: inline-block;
      width: 24px;
      height: 24px;
      background-color: var(--card-item-background);
      margin-right: 8px;
    }
  }

  &_actions {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    position: absolute;
    bottom: 0;
    background: var(--background-color);
    box-shadow: 0px -3px 8px 0px rgba(0, 0, 0, 0.25);
    padding: 0 26px;
    z-index: 2;
  }

  &_accordion .MuiExpansionPanelDetails-root {
    padding: 8px 7px 0 0;
  }
 }