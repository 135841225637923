.infoView {
  height: auto;
  border: none;
  padding: 10px 20px;
}

.options {
  padding-top: 24px;
  &.disabled {
    opacity: 0.5;
  }
}

.label {
  width: 113px;
}

.selectContainer {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  .select {
    width: 335px;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

.checkboxRoot {
  padding-left: 0;
}

.volume {
  margin-bottom: 12px;
}